body {
  background-color: #000;
  color: #FFF;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top-bar {
  background-color: #030626;
  width: 100%;
  padding: 20px 0px;
  display: inline-flex;
  justify-content: space-between;
}

.top-bar>h2 {
  margin-left: 20px;
}

.bg-logo {
  height: 80px;
  padding-left: 20px;
}

.event-info {
  padding-top: 10px;
}

.event-block {
  border-radius: 20px;
  border: 1px solid white;
  margin: 10px;
  padding: 10px;
  max-width: 48%;
}

.eb-header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.eb-vehicles {
  display: inline-flex;
  justify-content: space-between;
}

.eb-reg {
  background-color: yellow;
  color: black;
  font-weight: 600;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 10px;
  font-size: 30px;
}

.eb-kitlist {
  font-size: 24px;
  /*max-width: 25%;*/
  border-right: 1px solid white;
}

.eb-stafflist {
  font-size: 24px;
  /*max-width: 40%;*/
  border-left: solid 1px white;
}

.eba-header {
  display: inline-flex;
}

.eba-header > h2 {
  padding-right: 20px;
}

.event-block-admin {
  border-radius: 20px;
  padding: 10px;
  max-width: 100%;
  background-color: #54af6a;
}

.event-block-admin{
  margin-bottom: 20px;
}

.eb-body{
  max-width: 75% !important;
}